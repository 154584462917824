<template>
  <div>
    <v-btn
      class="mx-2 button-add"
      small
      @click.stop.prevent="handleModalFamille('show')"
      title="Ajouter une Famille"
    >
      <v-icon dark>
        mdi-plus
      </v-icon>
    </v-btn>
    <v-dialog
      v-model="addFamille"
      persistent
      max-width="600px"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="custom-vuetify-dialog-add"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Ajouter Famille </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="handleModalFamille('hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <v-card-text class="body-card">
          <form class="form-add">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Nom"
                  v-model="familleToAdd.name"
                  :persistent-placeholder="true"
                  :rules="[v => !!v || 'Nom Famille est obligatoire.']"
                  class="msg-error"
                  ref="nom"
                  color="#704ad1"
                  outlined
                >
                </v-text-field>
              </v-col>
            </v-row>
          </form>
          <div v-if="getErrorFamilleConfig" class="message-error-modal">
            <ul v-if="Array.isArray(getErrorFamilleConfig)" class="mb-0">
              <li v-for="(e, index) in getErrorFamilleConfig" :key="index">
                {{ e }}
              </li>
            </ul>
            <div class="message-error-modal" v-else>
              {{ getErrorFamilleConfig }}
            </div>
          </div>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            type="submit"
            @click.prevent="handleSubmitFamille"
            :loading="loading"
            :disabled="loading"
            :class="{ loader: loading }"
          >
            Valider
          </v-btn>
          <v-btn text @click.prevent.stop="handleModalFamille('hide')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  data() {
    return {
      addFamille: false,
      familleToAdd: { name: null },
      loading: false
    };
  },
  computed: {
    ...mapGetters(['getErrorFamilleConfig'])
  },
  methods: {
    ...mapActions(['addNewFamilleConfigFrais']),
    handleModalFamille(method) {
      if (method == 'show') {
        this.addFamille = true;
      } else {
        this.addFamille = false;
        this.familleToAdd.name = null;
        this.$refs.nom.reset();
      }
    },
    async handleSubmitFamille() {
      if (this.$refs.nom.validate()) {
        this.loading = true;
        const response = await this.addNewFamilleConfigFrais(this.familleToAdd);
        if (response) {
          this.loading = false;
          this.handleModalFamille('add', 'hide');
        } else {
          this.loading = false;
        }
      }
    }
  }
};
</script>

<style></style>
